import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="SyzygyLogo" />
      <div className="subtext">
        <h2>Web3 Game Studio.</h2>
        <h2><span className="underlinetext"><a href="mailto:jobs@syzygy.one">For info, contact us</a></span></h2>
      </div>
      <div className="VCLogo" />
    </div>
  );
}

export default App;
